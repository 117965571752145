import { Progress, Skeleton } from 'antd';
import React from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import KatexViewer from '@/helpers/Katex';

const MyQuizDetail = ({ quizDetail }) => {
  const skeleton = [1, 2];
  const quizSummary = quizDetail?.data?.data;
  const loading = quizDetail?.loading;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 p-3 border-right">
          <h5 className="heading">Quiz Score</h5>
          {loading ? (
            <Skeleton.Avatar
              className="d-flex justify-content-center text-center p-5"
              active={true}
              size={200}
            />
          ) : (
            <Progress
              strokeColor={{
                '0%': '#dc1f26',
                '100%': '#dc1f26',
              }}
              type="circle"
              width={200}
              className="d-flex justify-content-center text-center p-5"
              percent={quizSummary?.score}
            />
          )}
          <div className="mt-3">
            {loading ? (
              <h6 className="text-secondary">
                <Skeleton />
              </h6>
            ) : (
              <>
                <h6 className="text-secondary">Course: {quizSummary?.course}</h6>
                <h6 className="text-secondary">Chapter: {quizSummary?.chapter}</h6>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-6 p-3 quiz-summary">
          <h5 className="heading">Summary</h5>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Card className="p-3 mt-4 d-flex shadow-none border">
                <span>
                  {' '}
                  <strong>A: </strong>Total Question = {quizSummary?.total}
                </span>
              </Card>
              <Card className="p-3 mt-4 d-flex shadow-none border">
                <span>
                  {' '}
                  <strong>B: </strong>Attempted Question = {quizSummary?.attempted}
                </span>
              </Card>
              <Card className="p-3 mt-4 d-flex shadow-none border">
                <span>
                  {' '}
                  <strong>C: </strong>Unattempted Question ={' '}
                  {quizSummary?.unattempted}
                </span>
              </Card>
              <Card className="p-3 mt-4 d-flex shadow-none border">
                <span>
                  {' '}
                  <strong>D: </strong>Correct Answer = {quizSummary?.correct}
                </span>
              </Card>
              <Card className="p-3 mt-4 d-flex shadow-none border">
                <span>
                  {' '}
                  <strong>E: </strong>Incorrect Answer = {quizSummary?.incorrect}
                </span>
              </Card>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="question-section">
        <h5 className="heading">Quiz Questions</h5>
        {loading
          ? skeleton.map((data, index) => <Skeleton key={index} />)
          : quizSummary?.question?.map((quiz, index) => {
              return (
                <Card className="m-2 shadow-none border rounded-0" key={index}>
                  <CardHeader>
                    <CardTitle className="d-flex align-items-center ">
                      <strong className="text-secondary">
                        Question:{index + 1}
                      </strong>
                      <span className="ml-2">{quiz?.title}</span>
                      <span
                        className={`${
                          quiz?.answer__is_correct ? 'text-success' : 'text-danger'
                        } ml-2 pr-2`}
                      >
                        {quiz?.answer__is_correct ? (
                          <>
                            <CheckCircleOutlined rev="" />
                          </>
                        ) : (
                          <>
                            <CloseCircleOutlined rev="" />
                          </>
                        )}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="answer-section">
                      {/* <ul className="option-count">
                      {quiz?.answer?.map((data, i) => (
                        <li
                          className={`${data?.is_correct ? 'text-success' : ''}`}
                          key={i}
                        >
                          {i + 1}
                        </li>
                      ))}
                    </ul> */}
                      <ul className="option-collection mt-1">
                        {quiz?.answer?.map((data, i) => (
                          <li
                            className={`${
                              data?.is_correct
                                ? 'text-success'
                                : quiz?.attempted_answer_id === data?.id
                                ? 'text-primary'
                                : ''
                            } d-flex align-items-center ${
                              quiz?.answer?.length !== i + 1 && 'mb-2'
                            }`}
                            key={i}
                          >
                            <span className="mr-2">{i + 1}.</span>
                            {quiz?.has_expression ? (
                              <KatexViewer text={data?.text} />
                            ) : (
                              data?.text
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
      </div>
    </div>
  );
};

export default MyQuizDetail;
