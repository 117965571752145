import {
  addCourseReview,
  editCourseReview,
  getCourseEnroll,
  getRelatedCourses,
} from '@/actions/courses';
import CustomAntSearch from '@/components/CustomAntSearch';
import CustomCourseCarousel from '@/components/CustomCourseCarousel';
import { capitalizeFirstLetter } from '@/helpers/dataHelper';
import { Card, Empty, Form, Input, Modal, Progress, Rate, Spin } from 'antd';
import Link from 'next/link';
import router from 'next/router';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import MyQuizes from './myQuizes';

const MyCourse = ({ courses, currentPage }) => {
  const ratingOption = [
    'Awful',
    'Satisfactory',
    'Good',
    'Excellent',
    'Beyond Expectations',
  ];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [ratingVisible, setRatingVisible] = useState(false);
  const [courseRating, setCourseRating] = useState(1);
  const [reviewId, setReviewId] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [textValue, setTextValue] = useState(null);
  const { Search, TextArea } = Input;

  const { relatedCourses, coursePage, loading, courseReviewStatus } = useSelector(
    (state: any) => ({
      relatedCourses: state?.courses?.relatedCourse,
      coursePage: state.courses?.courseEnroll?.meta,
      loading: state.courses?.courseEnroll?.loading,
      quizSummary: state.quizReducer?.quizSummary,
      courseReviewStatus: state.courses?.courseReviewStatus,
    })
  );

  useEffect(() => {
    dispatch(getRelatedCourses());
  }, []);

  const searchMyCourses = (e) => {
    dispatch(getCourseEnroll(e.target.value, currentPage));
  };

  const handleSubmit = () => {
    // If course rating is greater than 0 then update review else add new review
    if (courseRating > 0) {
      const data: object = {
        feedback: textValue,
      };
      dispatch(editCourseReview(data, reviewId));
      setRatingVisible(false);
    } else if (courseRating === undefined) {
      const data: object = {
        feedback: textValue,
        course: courseId,
      };
      dispatch(addCourseReview(data));
      setRatingVisible(false);
    }
  };

  return (
    <>
      <div className="section-title">
        <h4>My Course</h4>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-8 ml-auto search-mycourse text-center">
        <CustomAntSearch onChange={(e) => searchMyCourses(e)} />
      </div>

      <Spin spinning={loading}>
        <section className="course-grid-area">
          <Container>
            {courses?.length !== 0 ? (
              <>
                <Row>
                  <CustomCourseCarousel courses={courses} myCourse={true} />

                  {/* rating modal */}
                  <Modal
                    visible={ratingVisible}
                    onCancel={() => setRatingVisible(false)}
                    title={
                      courseRating > 0
                        ? `Your Rating for this course`
                        : 'Give rating to this course'
                    }
                    footer={null}
                    className="modal-rate position-relative"
                  >
                    <Rate
                      className={`text-center`}
                      value={courseRating}
                      tooltips={ratingOption}
                    />
                    {courseRating > 0 ? (
                      <Form form={form} onFinish={handleSubmit}>
                        <Form.Item>
                          <TextArea
                            className="mt-2"
                            placeholder="Write about your personal experience while learning this course."
                            style={{ resize: 'none' }}
                            rows={6}
                            defaultValue={feedback}
                            name="feedback"
                            onChange={(e) => setTextValue(e.target.value)}
                          />
                          <button
                            type="submit"
                            className="default-btn-one mx-auto mt-2"
                            style={{ cursor: 'pointer' }}
                          >
                            Save
                          </button>
                        </Form.Item>
                      </Form>
                    ) : (
                      ''
                    )}
                  </Modal>
                </Row>
              </>
            ) : (
              <Empty />
            )}
          </Container>
        </section>
      </Spin>
      <MyQuizes />

      <div className="container-fluid related-courses">
        <div className="row">
          <div className="section-title mt-3">
            <br /> <br />
            <h4>What to learn Next?</h4>
          </div>
          <div className="container">
            <Row>
              <CustomCourseCarousel courses={relatedCourses?.data} />
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MyCourse);

export const MyCourseCard = ({ course }) => {
  return (
    <div className="course-items" key={course.slug}>
      <div className="course-item border-0" style={{ overflow: 'visible' }}>
        <Link href={`/course/lesson/${course.slug}`}>
          <a>
            <Card
              className={`cursor-pointer course-item-card course-card shadow-none rounded mb-1 montserrat-font-family`}
              cover={
                <img
                  alt={course?.name}
                  style={{ height: '135px' }}
                  src={`${
                    course.image
                      ? process.env.NEXT_PUBLIC_MEDIA_URL + course.image
                      : 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_09_25_20_46_51_BALLB_copy_2.jpg'
                  }`}
                />
              }
            >
              <Card.Meta
                className="d-flex align-items-center"
                title={
                  <span className="text-truncate course-single-name d-flex">
                    {capitalizeFirstLetter(course?.name)}
                  </span>
                }
              />
              <div className="mt-2 mb-2">
                <span className="course-instructor d-block">
                  {course?.test?.name ? (
                    <span className="text-muted" style={{ lineHeight: '0.5' }}>
                      {capitalizeFirstLetter(course?.test?.name)}
                    </span>
                  ) : null}
                </span>
                <div className="d-flex justify-content-between">
                  <>
                    <span className="course-rating mt-1">
                      {course?.total_feedback != 0 ? (
                        <>
                          <strong
                            className="mr-1 mt-1"
                            style={{
                              fontSize: '12px',
                              color: '#fbbc05',
                            }}
                          >
                            {course?.review?.course_rating?.toFixed(1)}
                          </strong>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={course?.review?.course_rating}
                            style={{
                              cursor: 'pointer',
                              fontSize: '13px',
                              color: '#fbbc05',
                            }}
                          />
                        </>
                      ) : null}
                    </span>
                  </>
                </div>
              </div>
              <div className="mt-3 mb-3 border-bottom-dotted" />
              <div className="position-relative d-flex justify-content-between pr-3">
                <Progress
                  className="w-100"
                  percent={course?.enrol_percentage}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#108ee9',
                  }}
                />
              </div>
            </Card>
          </a>
        </Link>
      </div>
    </div>
  );
};

export const MyNewCourseCard = ({ course }) => {
  return (
    <div className="course-items" key={course.slug}>
      <div className="course-item border-0" style={{ overflow: 'visible' }}>
        <Card
          className={`cursor-pointer course-item-card course-card mb-1 montserrat-font-family`}
          style={{
            borderRadius: '15px',
          }}
          cover={
            <img
              alt={course?.name}
              style={{
                height: '135px',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
              }}
              src={`${
                course.image
                  ? process.env.NEXT_PUBLIC_MEDIA_URL + course.image
                  : 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_09_25_20_46_51_BALLB_copy_2.jpg'
              }`}
            />
          }
          onClick={() =>
            course?.is_membership
              ? router.push({
                  pathname: `/course/lesson/${course.slug}/`,
                  query: {
                    subtab: course?.last_content?.subject,
                    chaptab: course?.last_content?.chapter,
                    activetab: course?.last_content?.id,
                  },
                })
              : router.push(`/course/lesson/preview/${course?.slug}?isRenew=true`)
          }
        >
          <Card.Meta
            className="d-flex align-items-center"
            title={
              <span className="text-truncate course-single-name d-flex">
                {capitalizeFirstLetter(course?.name)}
              </span>
            }
          />
          <div className="mt-2 mb-2">
            <span className="course-instructor d-block">
              {course?.test?.name ? (
                <span className="text-muted" style={{ lineHeight: '0.5' }}>
                  {capitalizeFirstLetter(course?.test?.name)}
                </span>
              ) : null}
            </span>

            <div className="d-flex justify-content-between">
              <>
                <span className="course-rating mt-1">
                  {course?.total_feedback != 0 ? (
                    <>
                      <strong
                        className="mr-1 mt-1"
                        style={{
                          fontSize: '12px',
                          color: '#fbbc05',
                        }}
                      >
                        {course?.review?.course_rating?.toFixed(1)}
                      </strong>
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={course?.review?.course_rating}
                        style={{
                          cursor: 'pointer',
                          fontSize: '13px',
                          color: '#fbbc05',
                        }}
                      />
                    </>
                  ) : null}
                </span>
              </>
            </div>
          </div>

          <div className="mt-3 mb-3 border-bottom-dotted" />
          <div className="position-relative d-flex justify-content-between pr-3">
            <Progress
              className="w-100"
              percent={course?.enrol_percentage}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#108ee9',
              }}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
